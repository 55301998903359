<template>
  <fcs-container fluid class="body overflow-auto vh-100 d-flex flex-column" align-v="center">
    <fcs-row>
      <Header :title=title></Header>
    </fcs-row>
    <fcs-row>
      <fcs-col class="mx-auto" lg="8">
        <SetParameters v-if="type === 'recipients'" :isEditWindow=true :card="card"
            :cardTitle = "isSecurityAlert ? $t('securityAlert.cardInfo.setParams.main') : $t('global.cardSubtitle')">
        </SetParameters>
        <ChooseRecurringSchedule v-if="type === 'schedule'" :isEditWindow=true :card="card" schedule-type="securityAlert"></ChooseRecurringSchedule>
      </fcs-col>
      <fcs-col class="mx-auto" lg="12">
        <AssignVehicle v-if="type === 'vehicle'" :isEditWindow=true :card="card"></AssignVehicle>
      </fcs-col>
    </fcs-row>
  </fcs-container>
</template>

<script>
import SetParameters from '@/components/common/SetParameters';
import Header from '@/components/common/Header';
import AssignVehicle from '@/components/common/AssignVehicle';
import { ALERT_TYPE } from '@/constants';
import { mapActions, mapMutations } from 'vuex';
import ChooseRecurringSchedule from '@/components/common/ChooseRecurringSchedule';

export default {
  name: 'EditRecipient',
  components: { AssignVehicle, SetParameters, Header, ChooseRecurringSchedule },
  props: {
    id: String,
    type: String
  },
  computed: {
    card () {
      return this.$store.state.inputArray.find(elem => elem.correlationID === this.id);
    },
    title () {
      return this.card.scheduleName;
    },
    isSecurityAlert () {
      return this.card.alertType === ALERT_TYPE.security;
    }
  },
  methods: {
    ...mapActions(['assignAlertType']),
    ...mapMutations(['setScheduleStatus'])
  },
  created () {
    this.assignAlertType(this.card.alertType);
    this.setScheduleStatus('');
  }
};
</script>
